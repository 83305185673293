//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { Form } from 'form-backend-validation'

export default {
  data () {
    return {
      login: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    async submit (e) {
      // await this.$auth.login({
      //   data: {
      //     username: this.login.username,
      //     password: this.login.password
      //   }
      // })
      e.preventDefault()

      await this.$auth
        .loginWith('local', {
          data: {
            username: this.login.username,
            password: this.login.password
          }
        })
        .then((data) => {
          if (data.status === 200) {
            this.$toast.success('Anda Berhasil Login!')
          }
        })
        .catch((err) => {
          const { data, status } = err.response
          if (status === 401) {
            this.$toast.error('Username atau Password salah!')
          } else if (status === 422) {
            this.$toast.error(data.data.message[0])
          }
        })
        .finally(() => {
          location.reload()
        })
    }
  }
}
